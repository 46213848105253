html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    scroll-behavior: smooth;

}

* {
    margin: 0;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

}



body {
    height: 100%;
    font-family: 'Muli',
    sans-serif;

    font-weight: 550;
    text-shadow: 1.5px 1.5px rgba(70, 65, 65, 0.26);
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-image: linear-gradient(rgb(0, 0, 36) 40%, rgba(25, 2, 36, 0.787));
    background-size:100%;   
    overflow: auto;
    scroll-behavior: smooth;



}


#about-me {
    margin-top: 12rem;
}

.name {
    font-family: 'Poppins',
    sans-serif;
    
font-weight: bolder;
color: rgb(245, 235, 216);
font-size: 90px;
padding: 0;
margin: 0;
text-shadow: 1px 7px 2px rgb(161, 51, 51);
}

.header {
    font-family: 'Poppins',
    sans-serif;
    
    font-weight: bolder;
    color: rgb(245, 235, 216);
    font-size: 44px;
    padding: 0;
    margin: 0;
}

.header-for-project {
    font-family: 'Poppins',
    sans-serif;
    
    font-weight: bolder;
    color: rgb(245, 235, 216);
    font-size: 32px;
    padding: 0;
    margin: 0;
    
}

.greeting {
    font-size: 36px;
}

.title-line {
    color: rgba(245, 222, 179, 0.123);
}

.tagline {
    font-family: 'Poppins',
    sans-serif;
    
    font-weight: bolder;
    color: #f5ebd8;
    font-size: 54px;
    padding: 0;
    margin: 0;
    
}

.intro-blurb {
    color: rgb(248, 129, 129);
    font-size: 24px;
}


.link-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 1px 1px 5px 1px rgba(153, 153, 155, 0.342);
    width: 100vw;
}

.nav-link {
    transition: all .3s ease-in-out;

}

.nav-link:hover {
    transform: translateX(10px);
    text-shadow: 1px 1px 1px rgba(170, 158, 158, 0.555);
}

.shadow {
    box-shadow: 0px 5px 3px 0px black;
}

.link-bar .nav-link, button {
    margin-left: 2rem;
    margin-right: 1rem;
}



.link-bar button:hover {
    color: white;
    border: 1px solid #000000;
    background-color: rgba(219, 146, 146, 0.609);
    
}

.icon-link {
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
}

.icon-link:hover .nav-icon-title {
    color: rgba(11, 11, 82, 0.781);
    visibility: visible;
}

.icon-img {
    max-height: 2rem;
    margin-bottom: 3px;
}





a:hover {
    text-decoration: none;
    
}




.site-layout {
    margin-top: 12rem;
    display: grid;
    grid-row-gap: 5rem;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
    "sidebar top-container ."
    ". about-container ."
    ". projects-container .";
    padding-bottom: 10rem;
}


.top-container{
    display: flex;
    flex-flow: column wrap;
    text-align: left;
    width: 50vw;
    margin: 0 auto;
    grid-area: top-container;
    
}




.aboutskills-container {
    color: white;
    background: rgba(214, 121, 121, 0.651);
    width: 50vw;
    padding: 30px;
    margin: 10rem auto;
    box-shadow: 40px 30px 2px 10px rgba(161, 157, 165, 0.157);
    grid-area: about-container;
    border-radius: 5px;

    
}





.about-paragraph {
    font-size: 20px;
}

.skills-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 5rem;

    margin-bottom: 5rem;
}

.skills-container figure {
    flex: 0 0 7%;
    margin-left: 1%;
    margin-right: 1%;
}


figure {
    margin: 0;
    padding: 0;
    text-align: center;
}




a {
    color: rgb(245, 235, 216);
    text-decoration: none;
}

a:hover {
    color: #ff6464;
}


.about-me-blurb {
    text-align: center;
}




.projects-header {
    margin: 5rem auto;
    padding-top: 2rem;
    width: 50vw;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    grid-area: projects-container
}





.projects-blurb {
    margin-bottom: 3rem;
    font-size: 20px;
}


.project-container {
    display: flex;
    flex-flow: row wrap;
}

.project {
    padding: 25px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin-bottom: 3.5rem;
    border-radius: 5px;
    transition: all .2s ease-in-out;

}

.project:hover {
    transform: translateY(-15px);
}

.project {
    box-shadow: -52px -22px 2px 3px rgba(230, 139, 79, 0.205);
    background: #a13333;


}

.project2shadow {
        box-shadow: -40px 30px 2px 10px rgba(226, 164, 123, 0.205);

}

.project3shadow {
        box-shadow: 60px -30px 2px 3px rgba(230, 139, 79, 0.205);
}



.project-description {
    background-color: rgba(2, 8, 27, 0.445);
    border: 1px solid rgba(0, 0, 0, 0.719);
    height: min-content;
    padding: 10px;

}

.icon {
    height: 3.5rem;
}

.project-picture {
    height: 300px;
    width: 25vw;
    margin-right: 2rem;
    border: 1px solid black;
    border-radius: 5px;
}

.tech-used-list {
    list-style-type: outside none none;
    text-align: left;
    margin: 0 auto;
    padding: 15px;
}

.footer {
    display: flex;
    justify-content: center;
    background-color: rgb(34, 11, 11);
    padding: 20px;
    box-shadow: -3px -1px 5px 1px rgba(153, 153, 155, 0.342);
}

.sidebar {
    display: flex;
    flex-flow: column wrap;
    width: 5vw;
    margin: 0 auto;
    height: 50vh;
    justify-content: space-around;
    align-items: center;
    grid-area: sidebar;
    position: sticky;
    top: 200px;
    bottom: 300px;
}

.sidebar-icon {
    height: 24px;
    width: 24px;
    transition: all .2s ease-in-out;
    color: white;
}

.dev-icon {
    background-color: white;
    border: 1px solid black;
}

.sidebar-icon:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.5);
    filter: sepia(30%);
}



.vertical {
    border-left: 3px solid rgba(255, 255, 255, 0.411);
    height: 200px;
    left: 50%;
}


@media screen and (min-width: 750px) {

}

@media screen and (min-width: 375px ) and (max-width: 749px ) {
    .site-layout {
        margin-top: 7rem;
        grid-template-rows: repeat(4, auto);
        grid-row-gap: 10rem;
        grid-template-areas: 
        "top-container"
        "about-container"
        "projects-container"
        "sidebar"
    }

    

    .skills-container figure {
        flex: 0 0 15%;
        margin-bottom: 2%;
        margin-left: 1%;
        margin-right: 1%;
    }

    .sidebar {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        position: static;
        width: 70vw;
        margin: 0 auto;
        top: 0;
        bottom: 0;
    }

    .python {
        align-self: flex-start;
        border: 1px solid red;
    }

    .vertical {
        border-left: 3px solid rgba(255, 255, 255, 0.411);
        transform: rotate(90deg);
        height: 80px;
        left: 50%;
    }

    .greeting {
        font-size: 24px;
    }

    .name {
        font-size: 72px;
    }

    .tagline {
        font-size: 36px;
    }

    .intro-blurb {
        font-size: 18px;
    }

    .top-container {
        width: 90vw;
        margin: 0 5px 0 auto;
    }

    .link-bar {
        justify-content: space-around;
        font-size: 13px;
        width: 100vw;
    }

    .project {
        flex-flow: column wrap;
    }

    .project-picture {
        width: 73vw;

        margin-bottom: 5px;
    }

    .project-description {
        height: min-content;
    }

    .button-link {
        font-size: 13px;
        padding: 6px;
        width: 60px;
    }

    button {
        margin-left: 0;
        margin-right: 0;
    }

    .link-bar .nav-link,
    button {
        margin-left: 0;
        margin-right: 0;
    }



    .aboutskills-container {
        width: 95vw;
        padding: 8px;
        box-shadow: 6px 30px 1px 10px rgba(89, 88, 90, 0.548)
    }

    .about-paragraph {
        width: 100%;
    }

    .project1shadow {
        box-shadow: 3px -22px 1px 3px rgba(44, 87, 83, 0.548);
    }

    .project2shadow {
        box-shadow: 10px 47px 1px 10px rgba(102, 96, 59, 0.548);
    }

    .projects-header {
        width: 95vw;
    }
}